<template>
  <v-card
    :style="{ height: '100%' }"
    class="card-outer"
  >
    <v-card-title
      v-if="title"
      class="pa-4"
    >
      <v-avatar
        size="24"
        class="mr-2 justify-start overview-card-title-icon"
        tile
      >
        <v-icon color="primary">
          {{ icon }}
        </v-icon>
      </v-avatar>
      {{ title }}
    </v-card-title>

    <v-divider />

    <v-card-text
      v-if="!table"
      class="px-4 pa-md-3"
    >
      <slot />
    </v-card-text>

    <slot v-else />

    <v-card-actions class="card-actions px-4">
      <v-container
        fluid
        class="pa-0"
      >
        <v-row
          align="center"
          justify="center"
          class="overview-card-buttons-row"
        >
          <slot name="button" />
          <v-col cols="auto">
            <v-btn
              rounded
              class="overview-card-button"
              depressed
              outlined
              color="primary"
              :to="buttonLink"
              :data-cy="'cy-' + buttonLink"
            >
              {{ buttonName }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    buttonName: {
      type: String,
      default: 'More'
    },
    buttonLink: {
      type: String,
      default: '/'
    },
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      required: true
    },
    table: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.v-btn.v-size--default.overview-card-button {
  max-width: 100%;
}

.overview-card-button > .v-btn__content {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex: auto;
}

.overview-card-title-icon {
  margin-left: -2px;
}

.overview-card-buttons-row {
  justify-content: space-evenly;
}
</style>
